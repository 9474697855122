export default {
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "bannerSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
  "bannerProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "sidebar": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistiques"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "tickets": {
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverts"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermés"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de tickets"])},
      "incomingEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mails Rentrants"])}
    },
    "incomingEmails": {
      "unlinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non liés à un client"])},
      "replied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses à des tickets clôturés"])}
    },
    "tools": {
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
      "sftpAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Accounts"])}
    },
    "survey": {
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enquêtes"])},
      "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponses"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les enquêtes"])}
    },
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "subMenu": {
      "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances"])},
      "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File d'appels"])}
    },
    "specialNavigation": {
      "expertService3CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Experts 3CX"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "georouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routages Géographiques"])},
      "callqueuegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes de files d'appels"])},
      "callqueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files d'appels"])}
    },
    "support": {
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
      "ticketCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories de tickets"])},
      "ticketStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuts de tickets"])},
      "emailFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres d'email"])}
    }
  },
  "header": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchTicketPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujets, dates de création ou dates de mise à jour"])},
    "searchInvoicePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures, dates ou totaux"])},
    "searchSftpAccountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'hôte ou nom d'utilisateur"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Actif"])},
    "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Compte"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Ticket"])},
    "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre profil"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par statut"])}
  },
  "chartTable": {
    "callType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
    "avgPerCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moyenne"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne fixe"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spéciale"])},
    "callCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels"])},
    "callDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels"])},
    "fileNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de document"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "choosePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la période"])},
    "chooseResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la ressource"])},
    "resourceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de ressource"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphiques"])},
    "data": {
      "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrant"])},
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortant"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondu"])},
      "unanswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Répondu"])}
    }
  },
  "dashboards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalier"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaire"])},
    "monthlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord Mensuel de"])},
    "monthlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données mensuelles de"])},
    "dailyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord Journalier de"])},
    "dailyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données journalières de"])},
    "hourlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de Bord par heure de "])},
    "hourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les données horaires de"])},
    "donutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels quotidien répondu et non répondu"])},
    "stackedColumnCategories": {
      "incomingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels Entrant"])},
      "outgoingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels Sortant"])},
      "answeredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels Répondu"])},
      "unansweredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'appels non Répondu"])},
      "incomingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Rentrant"])},
      "outgoingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Sortant"])},
      "answeredDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'appels Répondu"])}
    },
    "error": {
      "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
      "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données pour cette période n'ont pas été trouvées ou n'existent pas encore, nous sommes désolés pour ce désagrément."])}
    }
  },
  "warning": {
    "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée"])},
    "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune file d'attente d'appel n'a encore été créée. Veuillez en ajouter une en cliquant sur le bouton 'Nouvelle file d'appel'."])},
    "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe de file d'attente d'appel n'a encore été créée. Veuillez en ajouter un en cliquant sur le bouton 'Nouveau groupe de file d'appel'."])},
    "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun créneau horaire n'a encore été créé. Veuillez en ajouter un en cliquant sur le bouton 'Ajouter un nouveau créneau horaire'."])},
    "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun jours exceptionnels n'a encore été créé. Veuillez en ajouter un en cliquant sur l'un des boutons 'Ajouter une exception'."])}
  },
  "pbxExtension": {
    "selectData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une données"])},
    "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une période"])}
  },
  "home": {
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])}
  },
  "ticket": {
    "ticketForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire de création de Ticket"])},
    "typeDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez vos questions/problèmes ci-dessous"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catégorie"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priorité"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rèponse type"])},
    "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de résolution estimée"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous Catégorie"])},
    "selectProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le Problème"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "emailSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email attaché"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "typeAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez le problème ci-dessus"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
    "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template created successfully"])},
    "writeReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez votre réponse pour le ticket"])},
    "header": {
      "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverts"])},
      "myTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Tickets"])},
      "ticketsforTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journaliers"])},
      "ticketsForTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaires"])},
      "ticketsUnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assignés"])}
    },
    "sendToContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez la réponse au client"])},
    "sendToContactsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez d'envoyer la réponse au client"])},
    "sendToAssignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez la réponse au technicien assigné"])},
    "sendToAssigneeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réponse sera envoyée sous forme de note au technicien assigné"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturer le ticket"])},
    "closeTicketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondez et clôturez le ticket"])},
    "templateAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un template disponible"])},
    "assignedContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts associés"])},
    "addAssignedContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associer un contact"])}
  },
  "invoiceTable": {
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturer ID"])},
    "invoiceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
    "totalWithoutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant HT"])},
    "totalWithTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant TTC"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "searchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche ID"])},
    "searchNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche numéro"])},
    "searchMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "searchYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AAAA"])},
    "searchAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche montant"])}
  },
  "ticketTable": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réouvrir"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner des lignes"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher tout"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lignes"])},
    "confirmationCloseMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir fermer le ticket?"])},
    "confirmationOpenMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir réouvrir le ticket?"])},
    "closeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket fermé avec succès."])},
    "openMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ouvert avec succès."])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action réussie"])},
    "dialogTitleClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer le ticket."])},
    "dialogTitleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le ticket."])},
    "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage de"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
    "searchStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche status"])},
    "searchDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JJ/MM/AAAA"])}
  },
  "replySurveyTable": {
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score"])},
    "survey_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])},
    "sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent at"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "sftpAccountTable": {
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'hôte"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indisponible"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])}
  },
  "documentTable": {
    "allDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les documents"])},
    "yourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos documents"])}
  },
  "dialog": {
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner"])}
  },
  "profile": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de L'utilisateur"])},
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails personnels"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
    "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Token"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Actif"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes"])}
  },
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libérer"])},
  "actionId": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a demarré l'appel"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a commencé l'appel et c'est terminé par la source"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a commencé et terminé l'appel"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a laissé l'utilisateur patienter, puis l'a redirigé vers"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirige l'utilisateur vers"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a refusé l'appel ou n'a pas répondu"])},
    "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a laissé le téléphone sonner et n'a pas répondu"])},
    "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a refusé l'appel. L'appel a été automatiquement redirigé vers"])},
    "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'a pas pu répondre. L'appel a été automatiquement redirigé vers"])},
    "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n'a pas pu répondre car indisponible"])},
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le numéro sélectionné n'existe pas ou l'appel a échoué"])},
    "408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dont know about this action_id 408"])},
    "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a laissé le téléphone sonner et c'est mis en mode occupé"])},
    "418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a refusé l'appel ou l'a directement transféré à la messagerie vocal"])}
  },
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["début"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fin"])},
  "callLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal d'appels"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une heure"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réinitialiser"])}
}