import { createStore } from "vuex";
import { VueCookieNext } from "vue-cookie-next";

export default createStore({
  state: {
    user: {},
    isAuthenticated: false,
    avatar: localStorage.getItem("avatar") || "",
    token: localStorage.getItem("token") || null,
    language: localStorage.getItem("language") || "en",
    ticketCount: {},
    logo: localStorage.getItem("logo") || null,
    userId: localStorage.getItem("userId") || null,
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    isAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    avatar(state, avatar) {
      state.avatar = avatar;
    },
    token(state, token) {
      state.token = token;
    },
    language(state, language) {
      state.language = language;
    },
    logo(state, logo) {
      state.logo = logo;
    },
    userId(state, userId) {
      state.userId = userId;
    },
  },
  actions: {
    isAuthenticated(context, isAuthenticated) {
      context.commit("isAuthenticated", isAuthenticated);
    },
    user(context, user) {
      context.commit("user", user);
    },
    userAvatar(context, avatar) {
      context.commit("avatar", avatar);
    },
    token(context, token) {
      context.commit("token", token);
    },
    language(context, language) {
      context.commit("language", language);
    },
    logo(context, logo) {
      context.commit("logo", logo);
    },
    userId(context, userId) {
      context.commit("userId", userId);
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
    avatar: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    language: (state) => {
      return state.language;
    },
    logo: (state) => {
      return state.logo;
    },
    userId: (state) => {
      return state.userId;
    },
  },
});
