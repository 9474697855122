<template>
  <Banner
    v-if="
      isLocal === 'true' &&
        sandbox === 'false' &&
        this.$route.path !== '/login' &&
        this.$route.name !== '404'
    "
  />
  <div class="bg-gray-100 flex min_height_app">
    <Sidebar
      @openSideNavHover="openSideNavHover($event)"
      @keepOpenSideNavHover="
        (sideNavHover = true), (hideNoNav = true), (showSideNav = true)
      "
      :navigation="navigation"
      :secondaryNavigation="secondaryNavigation"
      :specialNavigation="specialNavigation"
      :showSpecialNavigation="showSpecialNavigation"
      :statisticsAvailableForProduction="statisticsAvailableForProduction"
      v-if="this.$route.path !== '/login' && this.$route.name !== '404'"
      @openNewSidebar="openSide = !openSide"
      @closeSubmenu="openSubmenu = false"
      :openSubmenu="openSubmenu"
    />

    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- Primary column -->
      <MainHeader
        v-if="this.$route.path !== '/login' && this.$route.name !== '404'"
        @openSubmenu="openSubmenu = true"
      />
      <div class="flex-1 flex items-stretch overflow-hidden">
        <aside
          @mouseleave="sideNavHover = false"
          v-show="
            showSideNav &&
              this.$route.name !== 'Login' &&
              hasSideNav &&
              sideNavHover
          "
          class="hidden side_nav_width navbar border-l border-gray-100 overflow-y-auto lg:block border-r border-t border-gray-300"
        >
          <SideNav
            @hasNavigation="hasSideNav = $event"
            @showSideNav="showSideNav = false"
            :navigation="
              sideHoverNav === 'sidebar.tickets.tickets'
                ? ticketNavigation
                : sideHoverNav === 'sidebar.survey.surveys'
                ? surveyNavigation
                : sideHoverNav === 'sidebar.tools.tools'
                ? toolsNavigation
                : sideHoverNav === 'sidebar.configuration'
                ? configurationNavigation
                : sideHoverNav === 'sidebar.hosts.hosting'
                ? hostingNavigation
                : undefined
            "
          />
        </aside>
        <aside
          v-show="
            showSideNav &&
              this.$route.name !== 'Login' &&
              this.$route.name !== 'CustomerDetail' &&
              this.$route.name !== 'Home' &&
              this.$route.name !== 'Customer' &&
              this.$route.name !== 'Statistics' &&
              this.$route.name !== 'HomeCharts' &&
              !this.$route.path.includes('newHost') &&
              !this.$route.path.includes('new-infrastructure') &&
              !this.$route.path.includes('open-ticket') &&
              hasSideNav &&
              !sideNavHover
          "
          class="hidden side_nav_width navbar border-l border-gray-100 overflow-y-auto lg:block border-r border-t border-gray-300"
        >
          <SideNav
            @hasNavigation="hasSideNav = $event"
            @showSideNav="showSideNav = false"
            v-if="this.$route.name"
            :navigation="
              (this.$route.name.includes('Ticket') ||
                this.$route.name.includes('IncomingEmails')) &&
              !this.$route.path.includes('support')
                ? ticketNavigation
                : this.$route.name.includes('Survey')
                ? surveyNavigation
                : this.$route.path.includes('support') ||
                  this.$route.path.includes('configuration')
                ? configurationNavigation
                : this.$route.path.includes('tools')
                ? toolsNavigation
                : this.$route.path.includes('hosting') &&
                  !this.$route.path.includes('configuration') &&
                  !this.$route.path.includes('newHost')
                ? hostingNavigation
                : undefined
            "
          />
        </aside>
        <aside
          v-show="!showSideNav && this.$route.name !== 'Login' && hasSideNav"
          class="hidden side_nav_off_width navbar border-l border-gray-100 overflow-y-auto lg:block border-r border-t border-gray-300"
        >
          <SideNavOff @showSideNav="showSideNav = true" />
        </aside>
        <main class="flex-1 overflow-y-auto">
          <!-- Primary column -->
          <section
            aria-labelledby="primary-heading"
            class="min-w-0 flex-1 h-full flex flex-col lg:order-last"
          >
            <router-view
              :key="$route.fullPath"
              @getTicketCount="getTicketsCount()"
            />
          </section>
        </main>

        <!-- Secondary column (hidden on smaller screens) -->
      </div>
    </div>
    <!-- <div :class="['flex-1 flex flex-col overflow-hidden']">
      

    </div> -->
  </div>
</template>

<script>
import axios from "axios";

import MainHeader from "./components/MainHeader.vue";
import Sidebar from "./components/Sidebar.vue";
import Banner from "./components/Banner.vue";
import SideNav from "./components/SideNav.vue";
import SideNavOff from "./components/SideNavOff.vue";
import { mapGetters } from "vuex";

const sandbox = localStorage.getItem("sandbox");
const isLocal = localStorage.getItem("isLocal");

const navigation = [
  // {
  //   name: "sidebar.customers",
  //   show: "",
  //   href: "/customers/1",
  //   icon: 'server',
  //   current: false,
  // },
  {
    name: "sidebar.statistics",
    show: "",
    href: "/",
    children: [],
    icon: "chart-line",
    current: false,
  },
  {
    name: "sidebar.hosts.hosting",
    show: "",
    href: "/hosting/hosts",
    children: [],
    icon: "server",
    current: false,
  },
  {
    name: "sidebar.tickets.tickets",
    show: "",
    href: "/tickets",
    children: [
      { name: "sidebar.tickets.open", href: "/tickets" },
      { name: "ticket.header.ticketsUnassigned", href: "/unassigned-tickets" },
      { name: "ticket.header.ticketsforTheDay", href: "/day-tickets" },
      { name: "ticket.header.ticketsForTheWeek", href: "/week-tickets" },
      { name: "ticket.header.myTickets", href: "/my-tickets" },
      { name: "sidebar.tickets.closed", href: "/closed-tickets/1" },
    ],
    icon: "headset",
    current: false,
  },
  {
    name: "sidebar.survey.surveys",
    show: "",

    href: "/reply-surveys/1",

    icon: "clipboard-list",
    children: [
      { name: "sidebar.survey.replies", href: "/reply-surveys/1" },
      { name: "sidebar.survey.all", href: "/all-surveys/1" },
    ],
    current: false,
  },
  {
    name: "sidebar.tools.tools",
    show: "",
    href: "/tools/sftp-accounts",
    icon: "wrench",
    current: false,
  },
];

const hostingNavigation = [
  { name: "sidebar.hosts.hosts", href: "/hosting/hosts" },
  { name: "sidebar.hosts.infrastructure", href: "/hosting/infrastructure" },
];

const toolsNavigation = [
  {
    name: "sidebar.tools.sftpAccounts",
    href: "/tools/sftp-accounts",
  },
];

const specialNavigation = [
  {
    name: "sidebar.configuration",
    show: "",
    href: "/support/tickets-categories",
    icon: "gear",
    children: [
      { name: "sidebar.tickets.templates", href: "/tickets-templates" },
      {
        name: "sidebar.support.ticketCategories",
        href: "/support/tickets-categories",
      },
      {
        name: "sidebar.support.ticketStatuses",
        href: "/support/tickets-statuses",
      },
      { name: "sidebar.support.emailFilters", href: "/support/email-filters" },
    ],
    current: false,
  },
];

const ticketNavigation = [
  { name: "sidebar.tickets.open", href: "/tickets", number: undefined },
  {
    name: "ticket.header.ticketsUnassigned",
    href: "/unassigned-tickets",
    number: undefined,
  },
  {
    name: "ticket.header.ticketsforTheDay",
    href: "/day-tickets",
    number: undefined,
  },
  {
    name: "ticket.header.ticketsForTheWeek",
    href: "/week-tickets",
    number: undefined,
  },
  { name: "ticket.header.myTickets", href: "/my-tickets", number: undefined },
  {
    name: "sidebar.tickets.closed",
    href: "/closed-tickets/1",
    number: undefined,
  },
  {
    name: "sidebar.tickets.incomingEmails",
    href: "",
    children: [
      {
        name: "sidebar.incomingEmails.unlinks",
        href: "/tickets/incoming-emails/unlink/1",
        number: undefined,
      },
      {
        name: "sidebar.incomingEmails.replied",
        href: "/tickets/incoming-emails/closed-tickets-replied/1",
        number: undefined,
      },
    ],
  },
];

const surveyNavigation = [
  { name: "sidebar.survey.replies", href: "/reply-surveys/1" },
  { name: "sidebar.survey.all", href: "/all-surveys/1" },
];

const configurationNavigation = [
  {
    name: "sidebar.tickets.tickets",
    href: "/support/tickets-categories",
    children: [
      { name: "sidebar.tickets.templates", href: "/support/tickets-templates" },
      {
        name: "sidebar.support.ticketCategories",
        href: "/support/tickets-categories",
      },
      {
        name: "sidebar.support.ticketStatuses",
        href: "/support/tickets-statuses",
      },
      { name: "sidebar.support.emailFilters", href: "/support/email-filters" },
    ],
  },
  {
    name: "sidebar.hosts.hosting",
    href: "/configuration/hosts",
    children: [
      {
        name: "sidebar.hosts.operatingSystems",
        href: "/configuration/hosting/hostOs",
      },
      {
        name: "sidebar.hosts.software",
        href: "/configuration/hosting/hostSoftwares",
      },
      { name: "sidebar.hosts.roles", href: "/configuration/hosting/hostRoles" },
      { name: "sidebar.hosts.types", href: "/configuration/hosting/hostTypes" },
      {
        name: "sidebar.hosts.shells",
        href: "/configuration/hosting/hostShells",
      },
      {
        name: "sidebar.hosts.functions",
        href: "/configuration/hosting/hostFunctions",
      },
      {
        name: "sidebar.hosts.supliers",
        href: "/configuration/hosting/hostSuppliers",
      },
      {
        name: "sidebar.hosts.datacenter",
        href: "/configuration/hosting/datacenters",
      },
      {
        name: "sidebar.hosts.hostServices",
        href: "/configuration/hosting/hostServices",
      },
    ],
  },
];

const secondaryNavigation = [];

const showSpecialNavigation = true;

const statisticsAvailableForProduction =
  process.env.VUE_APP_STATISTICS_PRODUCTION_AVAILABLE;

export default {
  title() {
    return `${localStorage.getItem("title")}`;
  },
  components: {
    MainHeader,
    Sidebar,
    Banner,
    SideNav,
    SideNavOff,
  },
  data() {
    return {
      navigation,
      secondaryNavigation,
      specialNavigation,
      showSpecialNavigation,
      configurationNavigation,
      surveyNavigation,
      ticketNavigation,
      toolsNavigation,
      hostingNavigation,
      statisticsAvailableForProduction,
      sandbox,
      isLocal,
      cssPath: "",
      openSide: false,
      openSubmenu: false,
      showSideNav: true,
      hasSideNav: false,
      sideNavHover: false,
      sideHoverNav: "",
      hideNoNav: false,
    };
  },
  methods: {
    initIcon(icon) {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = icon;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    openSideNavHover(event) {
      this.sideHoverNav = event;
      this.sideNavHover = true;
      this.hideNoNav = true;
    },
    showSubmenuTest(event) {
      console.log(event);
    },
    async getTicketsCount() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/tickets/counts`
        );
        this.ticketNavigation[0].number = res.data.open;
        this.ticketNavigation[1].number = res.data.unassigned;
        this.ticketNavigation[2].number = res.data.daily;
        this.ticketNavigation[3].number = res.data.weekly;
        this.ticketNavigation[4].number = res.data.assigned_to_myself;
        this.ticketNavigation[5].number = res.data.closed;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getApi() {
      try {
        axios
          .post("https://air-traffic.bluerocktel.net/api/flights")
          .then((res) => {
            console.log(res.data[0]);
            let apiData = "";
            if (Object.keys(res.data[0]).length > 1) {
              apiData = res.data[0].encom_admin_sanbox;
              localStorage.setItem("isLocal", true);
            } else {
              apiData = res.data[0].default;
              localStorage.setItem("isLocal", false);
            }
            console.log(apiData);
            if (apiData && apiData.active) {
              this.initIcon(apiData.logo);
              localStorage.setItem("sandbox", apiData.sandbox);
              localStorage.setItem("logo", apiData.logo);
              this.$store.dispatch("logo", apiData.logo);
              localStorage.setItem("title", apiData.title);
              if (!localStorage.getItem("language")) {
                localStorage.setItem("language", apiData.lang);
              }
              if (
                !this.$cookie.getCookie("API") ||
                this.$cookie.getCookie("API") !== apiData.baseUrl
              ) {
                localStorage.removeItem("token");
                this.$store.dispatch("token", null);
                this.$router.push("/login");
                this.$cookie.setCookie("API", apiData.baseUrl, {
                  expire: "21d",
                });
              }
            }
          })
          .then((res) => {
            this.getTicketsCount();
          });
      } catch (error) {
        this.errorHandling(error);
      }
    },
  },
  mounted() {
    this.getApi();
  },
  computed: {
    ...mapGetters(["token"]),
  },
};
</script>

<style>
.side_nav_width {
  min-width: 270px;
  overflow: hidden;
}
.side_nav_off_width {
  width: 50px;
  overflow: hidden;
}
</style>
