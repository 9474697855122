<template>
  <div class="mt-1 flex">
    <div class="relative flex-grow focus-within:z-10">
      <div
        :class="[
          !onFocus
            ? `absolute search-filter__icon border-t border-l border-b border-gray-300 inset-y-0 p-2 left-0 flex items-center pointer-events-none`
            : `absolute search-filter__icon inset-y-0 p-2 left-0 flex items-center pointer-events-none`,
          'rounded-l',
        ]"
        :style="[
          onFocus
            ? {
                'border-top': '1px solid',
                'border-left': '1px solid',
                'border-bottom': '1px solid',
                'border-color': '#1e7889',
              }
            : {},
        ]"
      >
        <SearchIcon class="h-5 w-5" aria-hidden="true" />
      </div>
      <input
        type="search"
        name="search"
        id="search"
        class="rounded search block w-full pl-11 sm:text-sm border-gray-300 focus:ring-0"
        @input="$emit('update:search', $event.target.value)"
        :placeholder="[!onFocus ? $t('header.search') : inputPlaceholder]"
        v-model="search"
        @focus="onFocus = true"
        @blur="onFocus = false"
      />
    </div>
  </div>
</template>

<script>
import { SortAscendingIcon, UsersIcon, SearchIcon } from "@heroicons/vue/solid";

export default {
  name: "SearchFilter",
  props: ["inputPlaceholder"],
  components: {
    SortAscendingIcon,
    UsersIcon,
    SearchIcon,
  },
  data() {
    return {
      onFocus: false,
      search: "",
    };
  },
};
</script>

<style scoped>
.search {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.search:focus {
  background-color: #fff;
  border-color: #1e7889;
  margin-inline-end: 160px;
}
</style>
