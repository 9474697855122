import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

const routes = [
  /*  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  }, */
  {
    path: "/tools/sftp-accounts",
    name: "SftpAccounts",
    component: () => import("../views/sftp/SftpAccounts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/email-filters",
    name: "EmailFilters",
    component: () => import("../views/configuration/support/EmailFilters.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: () => import("../views/tickets/Tickets.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/day-tickets",
    name: "DayTickets",
    component: () => import("../views/tickets/TicketsDay.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/week-tickets",
    name: "WeekTickets",
    component: () => import("../views/tickets/TicketsWeek.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/unassigned-tickets",
    name: "UnassignedTickets",
    component: () => import("../views/tickets/UnassignedTickets.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-tickets",
    name: "MyTickets",
    component: () => import("../views/tickets/MyTickets.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/closed-tickets/:page",
    name: "ClosedTickets",
    component: () => import("../views/tickets/ClosedTickets.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tickets/incoming-emails/unlink/:page",
    name: "OutTicketIncomingEmails",
    component: () =>
      import("../views/tickets/incomingEmails/OutTicketIncomingEmails.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tickets/incoming-emails/closed-tickets-replied/:page",
    name: "ReplyToClosedTicketIncomingEmails",
    component: () =>
      import(
        "../views/tickets/incomingEmails/ReplyToClosedTicketIncomingEmails.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tickets/:id/:ticketableId",
    name: "TicketDetail",
    component: () => import("../views/tickets/TicketDetail.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/tickets-templates",
    name: "TicketsTemplates",
    component: () => import("../views/tickets/TicketsTemplates.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/tickets-templates/:id",
    name: "TicketTemplateDetail",
    component: () => import("../views/tickets/TicketTemplateDetail.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customers/:page",
    name: "Customers",
    component: () => import("../views/customers/Customers.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customer-detail/:id",
    name: "CustomerDetail",
    component: () => import("../views/customers/CustomerDetail.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/open-ticket/:id",
    name: "OpenTicket",
    component: () => import("../components/tickets/OpenTicket.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/open-ticket/:id/:contactId",
    name: "OpenTicket",
    component: () => import("../components/tickets/OpenTicket.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-ticket/:ticketId/:customerId",
    name: "EditTicket",
    component: () => import("../components/tickets/EditTicket.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reply-surveys/:page",
    name: "ReplySurveys",
    component: () => import("../views/surveys/ReplySurveys.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/all-surveys/:page",
    name: "AllSurveys",
    component: () => import("../views/surveys/AllSurveys.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/survey-detail/:page/:id",
    name: "SurveyDetail",
    component: () => import("../views/surveys/ReplySurveysDetail.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/tickets-categories",
    name: "TicketCategories",
    component: () =>
      import("../views/configuration/support/TicketCategories.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/tickets-categories/:categoryId/:categoryName",
    name: "TicketSubCategories",
    component: () =>
      import("../views/configuration/support/TicketSubCategories.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/tickets-statuses",
    name: "TicketStatuses",
    component: () => import("../views/configuration/support/TicketStatus.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosting/hosts",
    name: "Hosts",
    component: () => import("../views/hosting/Hosts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosting/hosts/:hostId",
    name: "HostDetail",
    props: true,
    component: () => import("../views/hosting/HostDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosting/newHost/:id",
    name: "NewHost",
    props: true,
    component: () => import("../views/hosting/NewHost.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosting/new-infrastructure/:id",
    name: "NewInfrastructure",
    props: true,
    component: () => import("../views/hosting/NewInfraView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/configuration/hosting/:endPoint",
    name: "HostingConfiguration",
    props: true,
    component: () =>
      import("../views/configuration/hosting/HostingConfiguration.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosting/infrastructure",
    name: "Infrastructures",
    component: () => import("../views/hosting/Infrastructure.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("../views/customers/Customer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pbx-3cx-hosts",
    name: "Pbx3cxHosts",
    component: () => import("../views/Pbx3cxHosts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "Statistics",
    component: () => import("../views/statistics/Statistics.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home-charts",
    name: "HomeCharts",
    component: () => import("../views/HomeCharts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/hosts/:customerId",
  //   name: "Hosts",
  //   props: true,
  //   component: () => import("../views/hosting/Hosts.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresNotLogged: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../components/Error404.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

let entryUrl = null;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.token) {
      if (entryUrl) {
        const url = entryUrl;
        entryUrl = null;
        return next(url);
      }
      next();
      return;
    }
    entryUrl = to.path;
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresNotLogged)) {
    if (!store.state.token) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
