<template>
  <header class="w-full bg-white shadow">
    <div class="px-4 sm:px-6 lg:px-8 main_page_header">
      <div class="flex justify-between main_page_header">
        <div class="flex">
          <div class="-ml-2 mr-2 flex items-center md:hidden">
            <!-- Mobile menu button -->
            <button
              @click="$emit('openSubmenu')"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:encom_border_input"
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <!-- <div class="flex-shrink-0 flex pl-10">
            <a href="/"
              ><img class="hidden lg:block h-auto w-36" :src="logo" alt="Encom"
            /></a>
          </div> -->
        </div>
        <div
          class="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 sm:ml-40 sm:mr-40"
        >
          <div
            class="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0"
          >
            <QuickSearch @action="goToDetail($event)" :placeholder="'Search'" />
            <div class="flex-shrink-0 ml-2">
              <button
                @click="this.$router.push(`/customer`)"
                type="button"
                class="relative inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white header__button"
              >
                <PlusSmIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
                    /></svg
                ></span>
              </button>
            </div>
          </div>
        </div>

        <div class="flex items-center">
          <div class="flex-shrink-0">
            <LanguageSelector />
          </div>
          <div class="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
            <!-- Profile dropdown -->
            <Menu as="div" class="mr-3 relative">
              <div>
                <MenuButton
                  class="bg-white rounded-full flex text-sm focus:outline-none"
                >
                  <span class="sr-only">Open user menu</span>
                  <div class="flex-shrink-0">
                    <img
                      class="h-10 w-10 rounded-full"
                      :src="user.avatar"
                      alt=""
                      v-if="checkAvatar()"
                    />
                    <div v-if="!checkAvatar()">
                      <span
                        class="inline-block h-10 w-10 border border-gray-300 rounded-full overflow-hidden bg-gray-100"
                      >
                        <svg
                          class="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="z-30 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <!-- <MenuItem v-slot="{ active }">
                    <router-link
                      to="/profile"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 z-30',
                      ]"
                      >{{ $t("header.yourProfile") }}</router-link
                    >
                  </MenuItem> -->

                  <MenuItem v-slot="{ active }">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="signOut"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 z-30',
                      ]"
                      >{{ $t("header.logout") }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <!-- <button
              type="button"
              @click="this.$store.dispatch('search', !this.search)"
              class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:encom_border_input"
            >
              <span class="sr-only">View notifications</span>
              <SearchIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import {
  SearchIcon,
  MenuIcon,
  XIcon,
  ChevronDownIcon,
} from "@heroicons/vue/outline";
import { PlusIcon, PlusSmIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

import LanguageSelector from "../components/LanguageSelector.vue";
import QuickSearch from "../components/QuickSearch.vue";

const avatar = localStorage.getItem("avatar");
const logo = localStorage.getItem("logo");

export default {
  props: ["navigation", "specialNavigation", "showSpecialNavigation"],
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SearchIcon,
    MenuIcon,
    PlusIcon,
    XIcon,
    ChevronDownIcon,
    LanguageSelector,
    PlusSmIcon,
    QuickSearch,
  },
  data() {
    return {
      avatar,
      search: "",
      quickData: [],
      logo,
      searchableTerm: "",
    };
  },
  methods: {
    goToDetail(event) {
      let id = event.searchable_id;
      this.$router.push(`/customer-detail/${id}`);
    },
    signOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("account");
      // this.$cookie.removeCookie("token");
      localStorage.removeItem("userId");
      this.$store.dispatch("user", {});
      this.$store.dispatch("token", "");
      this.$store.dispatch("userAvatar", "");
      this.$store.dispatch("account", "");
      this.$router.push("/login");
      location.reload();
    },
    checkAvatar() {
      return this.avatar == "";
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style>
datalist {
  display: none;
}
</style>
