export default {
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "bannerSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sandbox"])},
  "bannerProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["production"])},
  "sidebar": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistics"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "tickets": {
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets Open"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets Closed"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets Templates"])},
      "incomingEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Emails"])}
    },
    "incomingEmails": {
      "unlinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink to a client"])},
      "replied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replied to closed tickets"])}
    },
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "tools": {
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "sftpAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP Accounts"])}
    },
    "survey": {
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surveys"])},
      "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["replies"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Surveys"])}
    },
    "subMenu": {
      "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial"])},
      "callQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues"])}
    },
    "specialNavigation": {
      "expertService3CX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Service 3CX"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "georouting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geographical Routing"])},
      "callqueuegroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues Groups"])},
      "callqueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Queues"])}
    },
    "support": {
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
      "ticketCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket categories"])},
      "ticketStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket statuses"])},
      "emailFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email filters"])}
    },
    "hosts": {
      "hosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hosts"])},
      "hosting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hosting"])},
      "software": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["software"])},
      "infrastructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infrastructure"])},
      "operatingSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operating systems"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roles"])},
      "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["types"])},
      "shells": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shells"])},
      "functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["functions"])},
      "supliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supliers"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datacenter"])},
      "hostServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])}
    }
  },
  "header": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchTicketPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject, created date or updated date"])},
    "searchInvoicePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice, date or total"])},
    "searchContactPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company, name, code or numbers"])},
    "searchSftpAccountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname or username"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Account"])},
    "accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open new ticket"])},
    "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Profile"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])}
  },
  "chartTable": {
    "callType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Type"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "avgPerCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg per Call"])},
    "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special"])},
    "callCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call count"])},
    "callDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call duration"])},
    "fileNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File number"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "choosePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose period"])},
    "chooseResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose resource"])},
    "resourceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource number"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])},
    "data": {
      "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
      "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
      "answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered"])},
      "unanswered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered"])}
    }
  },
  "dashboards": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
    "monthlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Dashboard of"])},
    "monthlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get monthly data of"])},
    "dailyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Dashboard of"])},
    "dailyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get daily data of"])},
    "hourlyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly Dashboard of"])},
    "hourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get hourly data of"])},
    "donutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Answered & Unanswered Calls Count"])},
    "stackedColumnCategories": {
      "incomingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Count"])},
      "outgoingCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Count"])},
      "answeredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered Count"])},
      "unansweredCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unanswered Count"])},
      "incomingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Duration"])},
      "outgoingDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing Duration"])},
      "answeredDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answered Duration"])}
    },
    "error": {
      "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
      "noDataFoundDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data for this period was not found or don't exist yet, we're sorry for the inconvenience."])}
    }
  },
  "warning": {
    "noDataFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
    "noDataFoundCallQueueDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No call queue created yet. Please add one by clicking on 'New call queue' button."])},
    "noDataFoundCallQueueGroupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No call queue group created yet. Please add one by clicking on 'New call queue group' button."])},
    "noDataFoundTimeSlotDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on 'Add New Time Slot' button."])},
    "noDataFoundExceptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slot created yet. Please add one by clicking on one of the 'Add Exception' buttons."])}
  },
  "pbxExtension": {
    "selectData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Data"])},
    "selectPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Period"])}
  },
  "home": {
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])}
  },
  "ticket": {
    "concerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concerns"])},
    "editTicketConfirmMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket updated successfully"])},
    "ticketForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket Form"])},
    "typeDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type down your questions/problems below"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priority"])},
    "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resolution"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Category"])},
    "selectProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Problem"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "emailSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email subject"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "typeAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the problem above"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "writeReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your reply for the ticket"])},
    "newTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["template created successfully"])},
    "deleteTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete template"])},
    "deleteTemplateAskConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the template?"])},
    "deleteTemplateConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template deleted successfully"])},
    "header": {
      "openTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open tickets"])},
      "ticketsforTheDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets for the day"])},
      "ticketsForTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets for the week"])},
      "ticketsUnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets unassigned"])},
      "myTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my tickets"])}
    },
    "sendToContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to contact"])},
    "sendToContactsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose to send the reply to the client"])},
    "sendToAssignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to assignee"])},
    "sendToAssigneeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose to send the reply as a note to the assignee agent"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close ticket"])},
    "closeTicketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose to reply and close the ticket"])},
    "templateAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an available ticket template"])},
    "assignedContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned contacts"])},
    "addAssignedContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add associate contact"])}
  },
  "invoiceTable": {
    "invoiceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice ID"])},
    "invoiceNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Number"])},
    "totalWithoutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Without Tax"])},
    "totalWithTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total With Tax"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "searchId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search ID"])},
    "searchNumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search number"])},
    "searchMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "searchYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY"])},
    "searchAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search amount"])}
  },
  "ticketTable": {
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "reopenTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
    "closeTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "selectRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select rows"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows"])},
    "confirmationCloseMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to close the ticket?"])},
    "confirmationOpenMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reopen the ticket?"])},
    "closeMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket closed successfully"])},
    "openMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket opened successfully"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action successful"])},
    "dialogTitleClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close ticket"])},
    "dialogTitleOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open ticket"])},
    "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
    "searchStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search status"])},
    "searchDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY"])}
  },
  "replySurveyTable": {
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["score"])},
    "survey_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])},
    "sent_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent at"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "sftpAccountTable": {
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "updatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last update"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file"])}
  },
  "documentTable": {
    "allDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All documents"])},
    "yourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your documents"])}
  },
  "dialog": {
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
  },
  "profile": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Information"])},
    "personalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal details"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "apiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Token"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "activeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Account"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])}
  },
  "contacts": {
    "searchAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search account"])},
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search company"])},
    "searchName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "searchNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search number"])},
    "contactAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact added successfully"])},
    "contactDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact deleted successfully"])}
  },
  "hostsTable": {
    "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
    "infrastructure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infrastructure"])},
    "shell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shell"])}
  },
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "actionId": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start the call"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has started the call and call finished by the source"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has started the call and call finished by the destination"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has let the user wait, then redirect to"])},
    "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is redirecting the user to"])},
    "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has decline the call or didn't answer"])},
    "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has left the phone ringing and did not answer"])},
    "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has refused the call. The call was automatically redirected to"])},
    "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["could not answer. The call was automatically redirected to"])},
    "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["could not answer because the destination was unavailable"])},
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the selected number does not exist or the call has failed"])},
    "408": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i dont know about this action_id 408"])},
    "409": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has left the phone ringing and went into busy mode"])},
    "418": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has refused the call or the source cancel the call"])}
  },
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["début"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fin"])},
  "callLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Logs"])},
  "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear"])}
}