<template>
  <div>
    <h1>{{ this.id }}</h1>
    <apexchart width="400" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: "DonutChart",
  props: [
    "showChart",
    "id",
    "dataSeries",
    "chartLabel",
    "title",
    "subtitle",
    "colors",
  ],
  data() {
    return {
      series: this.dataSeries,
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              size: "0%",
            },
          },
        },
        responsive: [
          {
            breakpoint: 680,
            options: {
              chart: {
                width: "350",
                offsetX: -10,
              },
              title: {
                text: this.title,
                align: "center",
                style: {
                  fontSize: "8px",
                },
              },
              subtitle: {
                text: this.subtitle,
                align: "center",
                offsetY: 25,
                style: { fontSize: "8px" },
              },
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
          {
            breakpoint: 1460,
            options: {
              chart: {
                width: "300",
                offsetX: -10,
              },
              title: {
                text: this.title,
                align: "center",
                style: {
                  fontSize: "8px",
                },
              },
              subtitle: {
                text: this.subtitle,
                align: "center",
                offsetY: 25,
                style: { fontSize: "8px" },
              },
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        chart: {
          type: "donut",
          events: {},
        },
        title: {
          text: this.title,
          align: "center",
          margin: 10,
        },
        subtitle: {
          text: this.subtitle,
          align: "center",
          offsetY: 25,
          style: { fontSize: "15px", fontWeight: "normal" },
        },
        legend: {
          show: true,

          position: "bottom",
        },
        labels: this.chartLabel,
        noData: {
          text: "Loading...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
      },
    };
  },
};
</script>

<style></style>
