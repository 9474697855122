import { VueCookieNext } from "vue-cookie-next";

export default {
  data() {
    return { errorStatus: "", errorStatusText: "", errorDataResponse: "" };
  },
  methods: {
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        dateCreated.getHours() +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
    errorHandling(err) {
      console.error(err.response);
      if (err.response.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("adminToken");
        this.$store.dispatch("token", null);
        this.$store.dispatch("adminToken", null);
        this.$router.push("/login");
      } else {
        this.errorStatus = err.response.status;
        this.errorStatusText = err.response.statusText;
        if (err.response.data.response) {
          this.errorDataResponse = err.response.data.response;
        } else if (err.response.data.message) {
          this.errorDataResponse = err.response.data.message;
        }
      }
    },
  },
};
