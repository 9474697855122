<template>
  <div
    class="z-10 top-0 sticky body__header border-t border-b ticket_table_heading"
  >
    <div class="sm:items-center top-0 flex flex-wrap justify-between">
      <div class="flex flex-wrap items-baseline">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ this.title }}
        </h3>
        <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
          {{ this.subtitle }}
        </p>
      </div>

      <div class="inline-flex">
        <div class="flex-1 ml-2 mt-3 xs:mt-0 lg:mt-0">
          <SearchFilter
            v-model:search="search"
            @input="$emit('update:search', this.search)"
            inputPlaceholder="Search by call id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { FilterIcon, SearchIcon } from "@heroicons/vue/outline";

import SearchFilter from "../SearchFilter.vue";

export default {
  name: "Header",
  props: ["searchStatus", "title", "subtitle"],
  components: {
    FilterIcon,
    SearchIcon,
    SearchFilter,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  data() {
    return {
      search: "",
      closeForm: false,
      onFocus: "Search by subject, created date or updated date",
      searchDate: "",
      searchDateUpdate: "",
      searchStatus: [],
      status: [],
      activeBtn: "",
    };
  },
  methods: {
    sendOpenForm() {
      this.$emit("openForm");
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {},
};
</script>

<style></style>
